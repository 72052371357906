<template>
  <header class="container text-center mt-5">
    <h1 class="mb-3">Christian Oellers</h1>
    <h2 class="mb-5">
      Entrepreneur / Developer / <span class="text-nowrap">Designer</span>
    </h2>
  </header>
</template>


<style>
h1 {
  font-family: Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;
  font-size: 5rem;
  font-weight: 600;
}

h2 {
  font-family: Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;
  font-size: 2rem;
}
</style>

