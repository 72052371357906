<template>
  <main role="main">
    <div class="container text-center mb-5">
      <h3>Imprint</h3>
      <hr />
      <h4 class="mb-3">This website belongs to Codeconut Ltd.</h4>
      <p>
        <a href="https://www.codeconutltd.com/legal-notes" target="_blank" rel="nofollow noopener noreferrer">Legal notes →</a>
      </p>
      <hr />
    </div>
  </main>
</template>

